import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const ErrorTitle = (): JSX.Element => {
  const { sadcloud } = useStaticQuery(graphql`
    query {
      sadcloud: file(relativePath: { eq: "images/sadcloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <div className='streetfocus-404'>
        <div className='streetfocus-404__title-wrapper'>
          <div className='streetfocus-404__sad-cloud-wrapper'>
            <div className='streetfocus-404__sad-cloud-wrapper'>
              <Img
                className='streetfocus-404__sad-cloud'
                fluid={sadcloud.childImageSharp.fluid}
              />
            </div>
            <h1>Oops..</h1>
            <h2>404 Error</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorTitle;
