import React from 'react';
import ErrorTitle from '../components/404Title';
import SEO from '../components/seo';

const Home = (): JSX.Element => {
  return (
    <>
      <SEO title='404' />

      <ErrorTitle />
    </>
  );
};

export default Home;
